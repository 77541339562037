.project-details {
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.6;
    margin: 20px;
  }
  
  h2 {
    font-weight: bold;
    margin-top: 20px;
    color:#0078C9;
  }
  h5{
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
  }
  h3,h4{
    font-weight: bold;
    color:#0078C9;
  }
  
  p {
    font-size: 14px;

  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  li {
    margin-top: 5px;
    font-size: 14px;
  }
  
  strong {
    font-weight: bold;
  }
  .imagestyling{
    margin-top: 10px;
    margin-bottom: 10px;
  }