.resizeable {
    resize: both;
    overflow: auto;
  }

.resizeable-left-div {
    resize: horizontal;
    overflow: auto;
    height: 830px !important;
    /* border: 2px solid black;
    transform: rotateX(180deg); */
    /* width: 350px !important; */
}

.transform-left-content,.resizeable-left-div{
    transform: rotateX(180deg);
    height: 100%;
    /* width: 100%; */
}

.resize-center-div {
    resize: both;
    overflow: auto;

}
.resize-table{
    resize: horizontal;
    overflow: auto;

}


.transform-center-div,.resize-center-div {
   /*transform: rotateX(180deg);*/
}
.transform-center-div{
    display: "flex";
    flex-direction: "row";
    flex-wrap: "wrap";
    width:"1200px";
}

.resizeable-rtl {
    resize: horizontal;
    overflow: auto;
    /* border: 2px solid black; */
    direction: rtl;
    /* width: 430px !important; */
    position: absolute;
    right:0 

} 



.transform-rtl,.resizeable-rtl {
    transform: rotateX(180deg);
    height: 830px;

    /* width: 100%; */
}


.content {
    direction: ltr;
}

  .graph {
    width: 200px !important;
    overflow: scroll;
    height: 150px !important;
  }

  .graphNew {
    width: 200px !important;
    overflow: scroll;
    height: 150px !important;
    resize: both;
    border: 2px solid black;

  }

  #pane {
	border: 1px solid black;
	background: #fefefe;
}

#title {
	font-family: monospace;
	background: black;
	color: white;
	font-size: 24px;
	height: 30px;
	text-align: center;
}

#ghostpane {
	background: #999;
	opacity: 0.2;

	width: 45%;
	height: 45%;
	top: 20%;
	left: 20%;
	
	position: absolute;
	margin: 0;
	padding: 0;
	z-index: 98;

	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}